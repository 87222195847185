import React, {useState} from 'react'
import {Nav} from "../../components";
import {FAQ_TEXT} from "../../data/faq-text.js";
import Plus from '../../images/icons/plus.svg';
import Minus from '../../images/icons/minus.svg';
import Footer from "../../components/footer";

const FAQ = ({location}) => {
    const {questions, answers} = FAQ_TEXT;
    const [openAnswers, setOpenAnswers] = useState([]);

    const toggleOpenAnswers = (answerId) => {
        setOpenAnswers(openAnswers.indexOf(answerId) > -1 ?
            [...openAnswers.filter(el => el !== answerId)] :
            [...openAnswers, answerId]
        );
    }

    const isAnswerOpen = (id) => {
        return openAnswers.indexOf(id) > -1
    }

    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'How to sell'}
            darkNav={true}
        />

        <div className="pageContainer faq">
            <header>
                <h1>How to sell</h1>
            </header>

            <section className="pageContent">
                <ul className="pageMenu">
                    {
                        questions.map(el => <li>
                            <a href={el.url} key={el.title}>
                                {el.icon}<span>{el.title}</span>
                            </a>
                        </li>)
                    }
                </ul>

                <div className="answers">
                    {
                        Object.keys(answers).map((group) => <div className={'group'} id={group} key={group}>
                            <h3>{group}</h3>
                            {answers[group].map(({title, id, texts}) => <div
                                key={id}
                                className={`answer collapsibleAnswer ${isAnswerOpen(id) ? 'open' : ''}`}
                                onClick={() => toggleOpenAnswers(id)}
                            >
                                <h4>{title}</h4>
                                <button className="collapsibleButton">
                                    {
                                        isAnswerOpen(id) ?
                                            <img src={Minus} alt="Close answer"/> :
                                            <img src={Plus} alt="Open Answer"/>
                                    }
                                </button>
                                {texts && <ul>
                                    {texts.map((text) => <p key={text}>{text}</p>)}
                                </ul>}
                            </div>)}
                        </div>)
                    }
                </div>
            </section>
        </div>

        <Footer />
    </div>
}

export default FAQ;