import WhereToSell from "../images/how-to-sell/nav-icons/where_to_sell.inline.svg";
import How2Sell from "../images/how-to-sell/nav-icons/how_to_sell.inline.svg";
import AcceptedDevices from "../images/how-to-sell/nav-icons/accepted_devices.inline.svg";
import PaymentProcess from "../images/how-to-sell/nav-icons/payment_process.inline.svg";
import React from "react";

export const FAQ_TEXT = {
    "page": "faq",
    "questions": [
        {
            "icon": <WhereToSell /> ,
            "title": "General",
            "url": '#general'
        },
        {
            "icon": <How2Sell /> ,
            "title": "Placing an order",
            "url": '#placing-order'
        },
        {
            "icon": <AcceptedDevices /> ,
            "title": "Shipping an item",
            "url": '#shipping-an-item'
        },
        {
            "icon": <PaymentProcess /> ,
            "title": "Payment process",
            "url": '#payment-process'
        }
    ],
    "answers": {
        "general": [{
            "title": "How is the \"Swaptecs Value\" offer calculated?",
            "id": '#where-to-sell',
            "texts": [
                "In order to calculate a competitive offer for your item, we have invested in tools that use data like market demand, business costs and available inventory to determine a competitive market value.",
                "There are a few things you should consider when deciding if selling to Swaptecs is the right choice for you:",
                "1. Taking pictures, composing descriptions, managing online accounts and postings can be a pain. With Swaptecs, you just need to answer a few quick questions to receive an offer.",
                "2. Here at Swaptecs, we know that determining the right asking price for your item can be difficult. In order to calculate a competitive offer for your item, we determine market demand, business costs and available inventory to give a competitive market value.",
                "3. Instead of waiting to find a seller, we will pay you fast after the condition of the item has been confirmed by a quick inspection process. Simply select your preferred payment method during the checkout process.",
            ]
        }],
    },
};